@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("assets/fonts/Unica77/stylesheet.css");

@font-face {
  font-family: "RidianCubic";
  src:
    url("assets/fonts/RidianCubic/RidianCubic-Regular.woff") format("woff"),
    url("assets/fonts/RidianCubic/RidianCubic-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "RidianCubicBold";
  src:
    url("assets/fonts/RidianCubic/RidianCubic-Bold.woff") format("woff"),
    url("assets/fonts/RidianCubic/RidianCubic-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "interSemiBold";
  src: url("assets/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
}
#root {
  height: 100%;
}
body {
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  background-color: #ffffff;
  @apply text-black;
  @apply bg-white;
  --purple: rgba(153, 0, 255, 0.5);
  --pink: rgba(0, 94, 255, 0.5);
  --blue: rgba(242, 13, 135, 0.5);
  --black: rgba(13, 12, 13, 0.5);
  --white: rgba(252, 252, 253, 0.5);
  --trans: rgba(0, 0, 0, 0);

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.fontFeatureSS01 {
  font-feature-settings: "ss01";
}

textarea:focus,
input:focus {
  outline: none;
}

.badge-QUOTE_REQUESTED {
  background-color: #fffadf;
  color: #8e4000;
}

.badge-SRC_CCY_DEPOSIT_RECIEVED,
.badge-SRC_CCY_RECEIVED {
  background-color: #e8dfef;
  color: #422454;
}

.badge-QUOTE_ACCEPTED {
  background-color: #e8dfef;
  color: #422454;
}

.badge-CANCELLED_BY_CLIENT,
.badge-CANCELLED_BY_MM {
  background-color: #fdeced;
  color: #d0021b;
}

.badge-QUOTE_GENERATED,
.badge-DEST_CCY_TRANSFER_PENDING,
.badge-SRC_CCY_DEPOSIT_PENDING {
  background-color: #d5f7f7;
  color: #387092;
}

.badge-SRC_CCY_DEPOSIT_RECIEVED,
.badge-CCY_CONVERSION_PENDING {
  background-color: rgba(135, 83, 38, 0.2);
  color: #8b572a;
}

.badge-COMPLETE {
  background-color: #e2ecd6;
  color: #43601e;
}

.badge-QUOTE_EXPIRED {
  background-color: #fdeced;
  color: #d0021b;
}

.badge-sell {
  background-color: #fdeced;
  color: #d0021b;
}
.badge-buy {
  background-color: #e2ecd6;
  color: #43601e;
}

li.loading {
  float: left;
  width: 16px;
  height: 30px;
  background: black;
  border: 1px solid black;
  box-sizing: border-box;
  margin-right: 8px;
  opacity: 0.2;
}

li.loading:nth-child(1) {
  animation-name: anim;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
li.loading:nth-child(2) {
  animation-name: anim;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
li.loading:nth-child(3) {
  animation-name: anim;
  animation-duration: 1s;
  animation-delay: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes anim {
  from {
    opacity: 1;
    transform: scale(1.1);
  }
  to {
    opacity: 0.2;
    transform: scale(1);
  }
}
.jer-input-text {
  width: 200px !important;
  padding: 20px !important;
}
.jer-key-text {
  cursor: pointer;
}

.jer-brackets {
  display: none;
}

.jer-editor-container {
  background-color: transparent !important;
}

.jer-type-select,.jer-select{
  height: 50px!important;
  
}
